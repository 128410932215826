/* eslint-disable max-lines-per-function */
import { AgGridAngular } from '@ag-grid-community/angular';
import { BaseExportParams, GetMainMenuItemsParams, GridApi, MenuItemDef, ProcessCellForExportParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';

import { TimezoneService } from 'src/app/_core/timezone.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { AgGridStateService } from 'src/app/_shared/ag-grid/ag-grid-state.service';
import { AgGridCheckboxHeaderComponent } from 'src/app/_shared/ag-grid/components/ag-grid-checkbox-header.component';
import { OrderException } from 'src/app/orders/models/order-exception.model';
import { WorkspaceGridData } from 'src/app/workspaces/details/grid/models/workspace-grid-data.model';

interface CustomExportParams {
  exceptionDefsMap?: Map<number, OrderException>;
}
@Injectable({
  providedIn: 'root'
})
export class AgGridService {
  constructor(private agGridStateService: AgGridStateService) {}

  /**
   * Automatically column header hight depending on header content.
   * This function is assigned to events in AgGridOptions service.
   * You might have to call it manually if events are overridden in component.
   */
  autoResizeColumnHeaders(gridApi: GridApi): void {
    if (!gridApi) {
      return;
    }
    // fix issue when more than one grid in page (e.g. item card > table)
    // @ts-ignore
    const gridId = gridApi.getModel().gridOptionsWrapper.environment.eGridDiv.id || '';
    const selector = gridId ? `#${gridId} .ag-header-cell-label` : '.ag-header-cell-label';
    const gridMinHeight = 24;
    gridApi.setHeaderHeight(gridMinHeight);
    const headerCells = document.querySelectorAll(selector);
    let minHeight = gridMinHeight;
    headerCells.forEach((cell) => {
      minHeight = Math.max(minHeight, cell.scrollHeight);
    });
    gridApi.setHeaderHeight(minHeight + 4);
  }

  /**
   * Add AGR's custom distinct aggregation option.
   */
  addDistinctAggregation(gridApi: GridApi): void {
    gridApi.addAggFunc('distinct', (params) => {
      const groups = groupBy(params.values);
      delete groups['']; // ignore empty values
      const groupSize = Object.keys(groups).length;
      if (groupSize === 1) {
        return Object.keys(groups)[0];
      }
      if (groupSize === 0) {
        return '';
      }
      return `${groupSize} (${TranslationsService.get('AG_GRID_DISTINCT')})`;
    });
  }

  /**
   * Adds Aligning options to column menu.
   */
  addCustomColumnMenu(key: string, grid: AgGridAngular): any {
    grid.getMainMenuItems = (params: GetMainMenuItemsParams) => {
      // Note: The menu changes depending on if the grid is grouped or not.
      const menuItems: (string | MenuItemDef)[] = params.defaultItems.splice(0); // use default menu items
      const align = {
        name: TranslationsService.get('AG_GRID_ALIGN'),
        subMenu: [
          {
            name: TranslationsService.get('AG_GRID_LEFT'),
            icon: '<i class="fa fa-align-left"></i>',
            action: () => {
              this.alignColumn('left', key, params);
            }
          },
          {
            name: TranslationsService.get('AG_GRID_CENTER'),
            icon: '<i class="fa fa-align-center"></i>',
            action: () => {
              this.alignColumn('center', key, params);
            }
          },
          {
            name: TranslationsService.get('AG_GRID_RIGHT'),
            icon: '<i class="fa fa-align-right"></i>',
            action: () => {
              this.alignColumn('right', key, params);
            }
          }
        ]
      };

      const clearFilters = {
        name: TranslationsService.get('AG_GRID_CLEAR_ALL_FILTERS'),
        action: () => {
          grid.api.setFilterModel({});
        }
      };

      const autoSizeThisColumnSkipHeader = {
        name: TranslationsService.get('AG_GRID_AUTOSIZE_THIS_COLUMN_SKIP_HEADER'),
        action: () => {
          grid.columnApi.autoSizeColumn(params.column, true);
        }
      };

      const autoSizeAllColumnsSkipHeader = {
        name: TranslationsService.get('AG_GRID_AUTOSIZE_ALL_COLUMNS_SKIP_HEADER'),
        action: () => {
          grid.columnApi.autoSizeAllColumns(true);
        }
      };

      const sizeColumnsToFit = {
        name: TranslationsService.get('AG_GRID_SIZE_COLUMNS_TO_FIT'),
        action: () => {
          grid.api.sizeColumnsToFit();
        }
      };

      menuItems.unshift(align); // add to front
      menuItems.splice(menuItems.indexOf('autoSizeThis') - 1, 0, 'separator', clearFilters);
      menuItems.splice(menuItems.indexOf('autoSizeThis') + 1, 0, autoSizeThisColumnSkipHeader);
      menuItems.splice(menuItems.indexOf('autoSizeAll') + 1, 0, autoSizeAllColumnsSkipHeader, sizeColumnsToFit);

      return menuItems;
    };
  }

  /**
   * Custom AG Grid Navigation.
   * When Enter is pressed:
   * • Navigate down if:
   *      Cell is not editable
   *      Cell is in row group
   *      If user is stopping cell edit
   * • Don't navigate if:
   *      'Shift' is pressed
   *      If starting cell edit
   */
  addEnterBtnEvents(grid: AgGridAngular, gridId?: string): void {
    let editing = false;
    grid.api.addEventListener('cellEditingStarted', () => (editing = true));
    grid.api.addEventListener('cellEditingStopped', () => (editing = false));
    // The rootSelector enables more than one grid to be loaded at a time
    const selector = gridId ? `#${gridId}` : 'ag-grid-angular.ag-theme-alpine';
    // Custom 'Enter' events (aka. Enter Enter)
    // enterMovesDownAfterEdit won't work because we want shift+enter to hold focus on current cell
    document.querySelector(selector).addEventListener('keydown', (evt: KeyboardEvent) => {
      if (editing && evt.key === 'Enter' && evt.shiftKey) {
        return; // shift + enter -> exit cell without navigating
      }
      if (editing && evt.key === 'Enter') {
        this.navigateDown(grid);
      }
    });
  }

  /**
   * Add column that contains checkboxes. Used in data grids.
   */
  addRowSelectColumn(grid: AgGridAngular): void {
    const colDefs = grid.columnApi.getAllColumns().map((column) => column.getColDef());
    if (colDefs.find((colDef) => colDef.field === 'RowSelect')) {
      return;
    }
    colDefs.unshift({
      headerName: TranslationsService.get('CHECKBOX'),
      field: 'RowSelect',
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      suppressMenu: true,
      enableRowGroup: false,
      filter: false,
      pinned: 'left',
      sortable: false,
      resizable: false,
      headerComponentFramework: AgGridCheckboxHeaderComponent,
      // Add checkbox when colId is 'RowSelect'
      checkboxSelection: (params) => {
        const rowSelectColumn = params.columnApi.getAllDisplayedColumns().find((column) => column.getColId() === 'RowSelect');
        return rowSelectColumn === params.column;
      }
    });
    grid.api.setColumnDefs(colDefs);
  }

  setFloatingFilter(isActive: boolean, grid: AgGridAngular): void {
    if (!grid) {
      return;
    }
    isActive ? grid.api.setFloatingFiltersHeight(38) : grid.api.setFloatingFiltersHeight(0);
  }

  /**
   * Exports to excel/csv with universal cellProcessing settings
   * cellCallback should be identical to clipboard processing in ag-grid-options.service.ts
   */
  exportToFile(output: 'excel' | 'csv', grid: AgGridAngular, fileName?: string, customParams?: CustomExportParams): void {
    // eslint-disable-line max-len
    const exportParams: BaseExportParams = {
      fileName,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const colDef = params.column.getColDef();
        if (colDef && colDef.field === 'field_name') {
          return this.getSerieName(params);
        }
        if (params.value instanceof Date) {
          return this.getExcelDateValue(params);
        }
        const isExceptionGroupColumn = !!colDef.cellRendererParams?.exceptionDefsMap && !!colDef.cellRendererParams?.isGrouped;
        if (colDef?.colId === 'exception_list' || isExceptionGroupColumn) {
          return this.getOrderExceptionsValue(params, customParams);
        }
        return params.value;
      }
    };
    output === 'excel' ? grid.api.exportDataAsExcel(exportParams) : grid.api.exportDataAsCsv(exportParams);
  }

  private getExcelDateValue(params: ProcessCellForExportParams): string {
    if (params.column.getColDef().type === 'date') {
      return TimezoneService.dateToString(params.value);
    }
    if (params.column.getColDef().type === 'datetime') {
      return TimezoneService.toISOStringLocal(params.value);
    }
    return params.value;
  }

  private getSerieName(params: ProcessCellForExportParams): string {
    const gridData: WorkspaceGridData = params.context.gridData;
    const serie = gridData.gridQuery.seriesByName[params.value];
    if (!serie) {
      return '';
    }
    return serie.caption;
  }

  private getOrderExceptionsValue(params: ProcessCellForExportParams, customParams: CustomExportParams): string {
    // eslint-disable-line max-len
    if (!customParams.exceptionDefsMap || !params.value) {
      return ' ';
    }
    if (params.value instanceof Array && params.value[0] === '') {
      return ' ';
    }
    const exceptionIds =
      params.value instanceof String ? params.value.split(',').filter((value) => !!value && value !== 'agg') : params.value; // eslint-disable-line max-len
    return exceptionIds instanceof Array
      ? exceptionIds.map((id) => TranslationsService.get(customParams.exceptionDefsMap.get(+id)?.name)).toString()
      : TranslationsService.get(customParams.exceptionDefsMap.get(+exceptionIds)?.name);
  }

  /**
   * Start editing next row after pressing enter key
   */
  private navigateDown(grid: AgGridAngular): void {
    const currentCell = grid.api.getFocusedCell();
    const finalRowIndex = grid.api.getDisplayedRowCount() - 1;
    // If we are editing the last row in the grid, don't move to next line
    const nextRowIndex = currentCell.rowIndex === finalRowIndex ? currentCell.rowIndex : currentCell.rowIndex + 1;
    setTimeout(() => {
      // Timeout required, Angular seems to cancel/revert the cell focus otherwise
      grid.api.setFocusedCell(nextRowIndex, currentCell.column.getColId());
      grid.api.clearRangeSelection();
    });
  }

  private alignColumn(aligning: string, key: string, params: GetMainMenuItemsParams): void {
    const colDef = params.column.getColDef() as any;
    colDef.aligning = aligning; // attribute used in gridOptionsService (see cellClassRules)
    this.agGridStateService.saveAlignModel(key, params);
    params.api.redrawRows();
  }
}

import { AgGridAngular } from '@ag-grid-community/angular';
import { ColumnApi, ColumnState, GetMainMenuItemsParams, GridApi } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';

import { StoreService } from 'src/app/_core/store.service';

/**
 * Interfaces are created from unnamed ag grid interfaces.
 * See getColumnGroupState().
 */
export interface AgGridColumnGroupState {
  groupId: string;
  open: boolean;
}

export interface AgrGridState {
  alignModel?: any;
  columnGroupState: AgGridColumnGroupState[];
  columnState: ColumnState[];
  filterModel: any;
}

@Injectable({
  providedIn: 'root'
})
export class AgGridStateService {
  constructor(private storeService: StoreService) {}

  // Load

  /**
   * Find state in local storage and applies it if found.
   */
  loadGridState(key: string, grid: AgGridAngular): void {
    this.loadAlignModel(key, grid);
    this.loadColumnState(key, grid);
    this.loadFilterModel(key, grid);
    this.loadFocusedCell(key, grid);
  }

  loadAlignModel(key: string, grid: AgGridAngular): void {
    const alignModel = this.storeService.get(this.getAlignModelKey(key));
    if (alignModel) {
      this.setAlignModel(alignModel, grid.columnApi);
    }
  }

  loadColumnState(key: string, grid: AgGridAngular): void {
    let columnStates = this.storeService.get(this.getColumnKey(key)) as ColumnState[];
    if (!columnStates) {
      return;
    }
    columnStates = this.fixRowSelectColumn(columnStates);
    grid.columnApi.applyColumnState({ state: columnStates, applyOrder: true });
  }

  loadFilterModel(key: string, grid: AgGridAngular): void {
    const filterModel = this.storeService.get(this.getFilterModelKey(key));
    if (filterModel) {
      grid.api.setFilterModel(filterModel);
    }
  }

  loadFocusedCell(key: string, grid: AgGridAngular): void {
    setTimeout(() => {
      const rowIndex = this.storeService.get(this.getFocusedCellRowIndexKey(key)) as number;
      const colKey = this.storeService.get(this.getFocusedCellColKeyKey(key)) as string;
      if (rowIndex === undefined || !colKey) {
        return;
      }
      grid.api.ensureIndexVisible(rowIndex, 'middle');
      if (grid.gridOptions.suppressRowClickSelection) {
        // data grids
        grid.api.setFocusedCell(rowIndex, colKey);
      } else {
        const node = grid.api.getDisplayedRowAtIndex(rowIndex);
        if (node) {
          node.setSelected(true);
        }
      }
    }, 100);
  }

  // Keys

  getAlignModelKey(key: string): string {
    return `${key}.alignModel`;
  }
  getColumnKey(key: string): string {
    return `${key}.columnState`;
  }
  getFilterModelKey(key: string): string {
    return `${key}.filterModel`;
  }
  getFocusedCellRowIndexKey(key: string): string {
    return `${key}.focusedCellRowIndex`;
  }
  getFocusedCellColKeyKey(key: string): string {
    return `${key}.focusedCellColKey`;
  }

  // Events

  // eslint-disable-next-line max-lines-per-function
  monitorChanges(key: string, grid: AgGridAngular, callback: any = () => {}): void {
    grid.api.addEventListener('cellFocused', () => {
      this.saveFocusedCell(key, grid.api);
      callback();
    });
    grid.api.addEventListener('columnMoved', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('columnResized', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('columnVisible', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('columnPinned', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('modelUpdated', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('rowGroupOpened', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('expandOrCollapseAll', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('sortChanged', () => {
      this.saveGridState(key, grid.columnApi);
      callback();
    });
    grid.api.addEventListener('filterChanged', () => {
      this.saveFilterModel(key, grid.api);
      callback();
    });
  }

  //////////////////////////////////////////

  /**
   * Get grid state. Used to save grid state in DB.
   */
  getState(grid: AgGridAngular): AgrGridState {
    return {
      alignModel: this.getAlignModel(grid.columnApi),
      columnGroupState: grid.columnApi.getColumnGroupState(),
      columnState: this.fixRowSelectColumn(grid.columnApi.getColumnState()),
      filterModel: grid.api.getFilterModel()
    };
  }

  /**
   * Check if state has been saved for given key.
   */
  hasGridState(key: string): boolean {
    const savedState = this.storeService.get(this.getColumnKey(key)) as ColumnState[];
    return !!savedState;
  }

  /**
   * Remove state from local storage.
   */
  clearState(key: string): void {
    this.storeService.remove(this.getColumnKey(key));
    this.storeService.remove(this.getFilterModelKey(key));
    this.storeService.remove(this.getAlignModelKey(key));
  }

  /**
   * Update colDef with aligning property.
   */
  setAlignModel(alignModel: {}, columnApi: ColumnApi): void {
    try {
      let column;
      let colDef;
      for (const colId of Object.keys(alignModel)) {
        column = columnApi.getColumn(colId);
        colDef = column.getColDef();
        colDef.aligning = alignModel[colId];
      }
    } catch (error) {
      return;
    }
  }

  // Save

  saveGrid(key: string, gridApi: GridApi, columnApi: ColumnApi): void {
    this.saveGridState(key, columnApi);
    this.saveFilterModel(key, gridApi);
  }

  saveAlignModel(key: string, params: GetMainMenuItemsParams): void {
    this.storeService.set(this.getAlignModelKey(key), this.getAlignModel(params.columnApi));
    params.api.dispatchEvent({ type: 'modelUpdated' }); // trigger event listener in monitorChanges
  }

  private saveFocusedCell(key: string, gridApi: GridApi): void {
    const focusedCell = gridApi.getFocusedCell();
    if (gridApi && focusedCell && focusedCell.column) {
      this.storeService.set(this.getFocusedCellRowIndexKey(key), focusedCell.rowIndex);
      this.storeService.set(this.getFocusedCellColKeyKey(key), focusedCell.column.getId());
    }
  }

  private saveGridState(key: string, columnApi: ColumnApi): void {
    if (columnApi) {
      this.storeService.set(this.getColumnKey(key), this.fixRowSelectColumn(columnApi.getColumnState()));
    }
  }

  private saveFilterModel(key: string, gridApi: GridApi): void {
    if (gridApi) {
      this.storeService.set(this.getFilterModelKey(key), gridApi.getFilterModel());
    }
  }

  //////////////////////////////////////////

  /**
   * Creates align model depending on colDef.aligning.
   * e.g. {item_no: "center", primary_vendor_id: "right"}
   */
  private getAlignModel(columnApi: ColumnApi): {} {
    const alignModel = {};
    const visibleColumns = columnApi.getAllDisplayedColumns();
    let colDef;
    visibleColumns.forEach((column) => {
      colDef = column.getColDef();
      if (colDef.aligning) {
        alignModel[column.getColId()] = colDef.aligning;
      }
    });

    return alignModel;
  }

  /**
   * Moves checkboxes column to far left and ensures it is visible.
   *
   * RowSelect column is the checkbox column.
   * Column Defs for RowSelect column is in AgGridService.
   */
  private fixRowSelectColumn(columnStates: ColumnState[]): ColumnState[] {
    const rowSelectState = columnStates.find((state) => state.colId === 'RowSelect');
    if (rowSelectState) {
      rowSelectState.hide = false;
      const index = columnStates.indexOf(rowSelectState);
      columnStates.unshift(columnStates.splice(index, 1)[0]); // Move to first
    }
    return columnStates;
  }
}

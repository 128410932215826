<div class="agr-layout h-100">
  <div class="agr-layout-section h-100">
    <!-- Item card header -->
    <div class="agr-layout-header agr-toolbar">
      <!-- Breadcrumb -->
      <div class="agr-breadcrumbs card-breadcrumbs">
        <div id="card-breadcrumbs-location" class="text-truncate" ngbTooltip="{{ 'LOCATION' | agrTranslate }}: {{ item.location.name }}">
          {{ item.location.name || '---' }}
        </div>
        <div class="card-breadcrumbs-chevron" *ngIf="item.location.name">
          <i class="fa fa-chevron-right"></i>
        </div>
        <div
          id="card-breadcrumbs-item"
          *ngIf="item.location.name"
          class="text-truncate u-cursor-pointer"
          (click)="toggleTitle()"
          title="{{ 'ITEM' | agrTranslate }}: {{ item.articleNo }} - {{ item.name }}">
          {{ state.showTitleItemNo ? item.itemNo || '---' : item.name || '---' }}
        </div>

        <button
          id="lock-item-btn"
          class="btn agr-toolbar-btn agr-toolbar-btn-default ms-3"
          [class.active]="isLocked"
          [placement]="isPage ? 'bottom' : 'top'"
          ngbTooltip="{{ 'LOCK_ITEM' | agrTranslate }}"
          (click)="toggleLockItem()">
          <i class="fa" [ngClass]="isLocked ? 'fa-lock' : 'fa-unlock'"></i>
        </button>
      </div>

      <span class="navbar-divider"></span>
      <!-- Section/Component Tab navigation -->
      <ul class="agr-toolbar-tabs nav-flex-tabs">
        <li
          class="nav-link agr-toolbar-btn-default btn"
          id="chart-tab-link"
          [ngClass]="{ 'active card-active': state.showChart }"
          (click)="toggleCard('showChart')"
          [attr.aria-expanded]="!state.showChart"
          aria-controls="item-chart"
          data-toggle="tab">
          {{ 'CHART' | agrTranslate }}
        </li>
        <li
          class="nav-link agr-toolbar-btn-default btn"
          id="grid-tab-link"
          [ngClass]="{ 'active card-active': state.showGrid }"
          (click)="toggleCard('showGrid')"
          [attr.aria-expanded]="!state.showGrid"
          aria-controls="item-grid"
          data-toggle="tab"
          [attr.aria-expanded]="!state.showGrid"
          aria-controls="item-grid"
          data-toggle="tab">
          {{ 'GRID' | agrTranslate }}
        </li>
        <li
          class="nav-link btn agr-toolbar-btn-default btn"
          id="details-tab-link"
          [ngClass]="{ 'active card-active': state.showDetails }"
          data-toggle="tab"
          (click)="toggleCard('showDetails')"
          [attr.aria-expanded]="!state.showDetails"
          aria-controls="item-details">
          {{ 'DETAILS' | agrTranslate }}
        </li>
      </ul>
      <ul class="agr-toolbar-tabs nav-toggle-tabs">
        <li
          class="nav-link btn active card-active agr-toolbar-btn-default"
          id="active-card-tab"
          (click)="toggleNextCard()"
          data-toggle="tab">
          {{ state.activeCard | uppercase | agrTranslate }}<span class="u-chevron-right"></span>
        </li>
      </ul>

      <!-- Labels -->
      <div class="agr-toolbar-labels" *ngIf="!isEmpty">
        <div *ngFor="let label of itemLabels; let index = index">
          <ng-template #labelInfo>
            <div class="mb-2">
              <h5>{{ label.info.HeaderCaption | agrTranslate }}</h5>
              <span
                style="font-size: small"
                *ngFor="let info of label.info.Body; let index = index"
                innerHTML="{{ info?.TranslationString | agrTranslate }}"></span>
            </div>
          </ng-template>
          <button
            [ngbPopover]="labelInfo"
            class="btn badge rounded-pill text-white mx-1 text-truncate label"
            [ngClass]="label.color"
            [disabled]="label.info === null"
            data-cy="label-badge">
            {{ label.caption | agrTranslate }}
          </button>
        </div>
      </div>
      <div class="agr-tools">
        <span class="navbar-divider"></span>
        <!-- Toolbar buttons -->
        <!-- Refresh Item Card button -->
        <button
          id="refresh-item-btn"
          class="btn agr-toolbar-btn agr-toolbar-btn-default"
          [placement]="isPage ? 'bottom' : 'top'"
          ngbTooltip="{{ 'REFRESH' | agrTranslate }}"
          (click)="refreshItem()"
          [class.active]="">
          <i class="fa fa-refresh"></i>
        </button>

        <span class="navbar-divider"></span>

        <!-- Time interval, history- and forecast length -->
        <div class="agr-tools-input" *ngIf="!state.showDetails">
          <button id="period-type-btn" class="btn agr-toolbar-btn" (click)="toggleChartPeriod()">
            {{ state.selectedPeriodType | agrTranslate }}
          </button>
          <div>
            <i
              id="history-increment-btn"
              class="fa fa-long-arrow-left"
              [placement]="isPage ? 'bottom' : 'top'"
              ngbTooltip="{{ 'HISTORY' | agrTranslate }}"
              (click)="stepIncrementHistory()"></i>
            <input
              id="history-input"
              type="number"
              class="form-control"
              [value]="state.chart.historyLength"
              (change)="updateHistoryLength(+$event.target.value)"
              min="-1" />
            <input
              id="forecast-input"
              type="number"
              class="form-control"
              [value]="state.chart.forecastLength"
              (change)="updateForecastLength(+$event.target.value)"
              min="-1" />
            <i
              id="forecast-increment-btn"
              class="fa fa-long-arrow-right"
              [placement]="isPage ? 'bottom' : 'top'"
              ngbTooltip="{{ 'FORECAST' | agrTranslate }}"
              (click)="stepIncrementForecast()"></i>
          </div>
        </div>

        <span class="navbar-divider" *ngIf="!state.showDetails"></span>

        <!-- Recalculate purchase plan button -->
        <button
          id="recalculate-purchase-plan-btn"
          class="btn agr-toolbar-btn agr-toolbar-btn-default"
          [placement]="isPage ? 'bottom-right' : 'top-right'"
          ngbTooltip="{{ 'RECALCULATE_CHART_DATA' | agrTranslate }}"
          (click)="recalculatePurchasePlan()"
          *ngIf="!state.showDetails && !isRecalculating">
          <i class="fa fa-calculator"></i>
        </button>
        <button
          id="recalculate-purchase-plan-btn"
          class="btn agr-toolbar-btn agr-toolbar-btn-default"
          [placement]="isPage ? 'bottom-right' : 'top-right'"
          ngbTooltip="{{ 'RECALCULATE_CHART_DATA' | agrTranslate }}"
          (click)="recalculatePurchasePlan()"
          *ngIf="!state.showDetails && isRecalculating"
          disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>

        <span class="navbar-divider" *ngIf="!state.showDetails"></span>

        <!-- Image sidebar button-->
        <agr-toolbar-btn
          *ngIf="hasImage"
          btnType="image"
          [placement]="isPage ? 'bottom' : 'top'"
          (click)="toggleImageSidebar()"
          [active]="state.imageSidebar.visible">
        </agr-toolbar-btn>
        <!-- Info/Details sidebar button-->
        <agr-toolbar-btn btnType="info" [placement]="isPage ? 'bottom' : 'top'" (click)="toggleSidebar()" [active]="state.sidebar.visible">
        </agr-toolbar-btn>

        <span class="navbar-divider"></span>

        <!-- Open in new window button -->
        <agr-toolbar-btn
          *ngIf="!isPage && hasNewWindowSupport"
          btnType="newWindow"
          [placement]="isPage ? 'bottom-right' : 'top-right'"
          (click)="openItemWindow()">
        </agr-toolbar-btn>
        <!-- Close button -->
        <agr-toolbar-btn btnType="close" (click)="close()"></agr-toolbar-btn>
      </div>
    </div>

    <!-- Item card body -->
    <div class="agr-layout-body">
      <!-- Image Sidebar-->
      <div
        id="item-card-image-sidebar"
        class="agr-layout-sidebar agr-layout-sidebar-left bg-white"
        *ngIf="hasImage && state.imageSidebar.visible"
        agrResizable="sidebar"
        [resizeEdges]="{ right: true }"
        [minWidth]="60"
        [style.width.px]="state.imageSidebar.size"
        (resizeEnd)="state.imageSidebar.size = $event.rectangle.width"
        (resizeEnd)="saveState()">
        <div mwlResizeHandle [resizeEdges]="{ right: true }" class="resize-handle-right"></div>
        <!-- Image -->
        <div class="agr-layout-sidebar-content text-center" #imageContainer>
          <img
            *ngIf="imageUrl && !isEmpty"
            src="{{ imageUrl }}"
            alt=""
            class="product-image"
            [ngStyle]="{ 'max-width.px': state.imageSidebar.size - 30, 'max-height.px': imageMaxHeight }"
            onError="this.src='assets/images/product-image-placeholder.png';" />
          <agr-loading-dots *ngIf="!imageUrl && itemId" [size]="'small'"></agr-loading-dots>
        </div>
      </div>

      <div class="agr-layout-main">
        <agr-item-card-chart
          id="item-chart"
          class="h-100"
          *ngIf="state.showChart"
          [ngbCollapse]="!state.showChart"
          [itemId]="itemId"
          [orderId]="orderId"
          [chartParams]="state.chart"
          [refreshItemEvent]="refreshItemEvent.asObservable()"
          [refreshDetailsColumnEvent]="refreshDetailsColumnEvent.asObservable()">
        </agr-item-card-chart>
        <agr-item-card-grid
          id="item-grid"
          class="agr-layout-main-content h-100"
          *ngIf="state.showGrid"
          [ngbCollapse]="!state.showGrid"
          [orderId]="orderId"
          [itemId]="itemId"
          [chartParams]="state.chart"
          [refreshItemEvent]="refreshItemEvent.asObservable()"
          [refreshDetailsColumnEvent]="refreshDetailsColumnEvent.asObservable()">
        </agr-item-card-grid>
        <agr-item-card-details
          id="item-details"
          class="h-100"
          *ngIf="state.showDetails"
          [ngbCollapse]="!state.showDetails"
          [itemId]="itemId"
          [refreshItemEvent]="refreshItemEvent.asObservable()"
          [refreshDetailsColumnEvent]="refreshDetailsColumnEvent.asObservable()"
          (columnChangedEvent)="refreshDetails($event)">
        </agr-item-card-details>
      </div>

      <!-- Sidebar/Item details-->
      <div
        id="item-card-sidebar"
        class="agr-layout-sidebar agr-layout-sidebar-right"
        *ngIf="state.sidebar.visible"
        agrResizable="sidebar"
        [resizeEdges]="{ left: true }"
        [minWidth]="260"
        [style.width.px]="state.sidebar.size"
        (resizeEnd)="state.sidebar.size = $event.rectangle.width"
        (resizeEnd)="saveState()">
        <div mwlResizeHandle [resizeEdges]="{ left: true }" class="resize-handle-left"></div>
        <agr-item-card-details
          id="item-details-sidebar"
          class="agr-layout-component"
          [itemId]="itemId"
          [isSidebar]="true"
          [refreshItemEvent]="refreshItemEvent.asObservable()"
          [refreshDetailsColumnEvent]="refreshDetailsColumnEvent.asObservable()"
          (closeSidebar)="toggleSidebar()"
          (columnChangedEvent)="refreshDetails($event)">
        </agr-item-card-details>
      </div>
    </div>
  </div>
</div>
